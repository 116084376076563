<template>
  <v-container fluid class="mt-4">
    <v-container fluid >
      <v-row>
        <v-col cols="12">
          <h1>Ostatnio dodane przepisy</h1>
          <h4 class="text-typo">{{ this.recipes.length }} wyniki</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            v-for="recipe in recipes.slice(pageRecipe*9,(pageRecipe*9)+9)"
            :key="recipe.resourceid"
        >
          <v-card
              class="transparent pa-1 thumbnail-container"
              @click="openRecipe(recipe.resourceid)"
          >
            <v-img
                :src="recipe.image.url"
                elevation="1"
                class="rounded thumbnail-static"
            >
              <template v-slot:placeholder>
                <v-sheet
                    :color="`grey lighten-4`"
                    :style="'height: 100%; width: 100%;'"
                >
                  <v-skeleton-loader
                      type="image"
                      :color="`grey lighten-4`"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
            <div class="recipe-liked" v-if="recipe.isLiked">
              <v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 35px !important;'"
              >fas fa-heart</v-icon
              >
            </div>

            <h4 class="text-typo">
              {{ recipe.name }}
              <v-chip
                  class="ma-2"
                  x-small
                  color="green"
                  text-color="white"
                  v-if="!userHasActivePlan && recipe.is_free"
              >
                FREE
              </v-chip>
            </h4>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-inline-flex justify-center align-center">
          <v-btn class="btn-primary button-primary-gradient" :disabled="pageRecipe==0" @click="prevPageRecipe()">
            <v-icon :style="'color: white;'" class="ma-2">
              fas fa-chevron-left
            </v-icon>
          </v-btn>
          <h4 class="text-typo mx-2">strona {{pageRecipe+1}}/{{maxPageRecipe}}</h4>
          <v-btn class="btn-primary button-primary-gradient" :disabled="pageRecipe>=(maxPageRecipe-1)" @click="nextPageRecipe()">
            <v-icon :style="'color: white;'" class="ma-2">
              fas fa-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-if="currentRecipe"
        v-model="recipeDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        width="800"
        :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="recipeDialog = false">
            <v-icon color="white">{{
                this.$vuetify.breakpoint.mobile
                    ? "fas fa-chevron-left"
                    : "fas fa-times"
              }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
          >{{ currentRecipe.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="8" lg="7" md="7" class="d-flex justify-center">
              <v-img
                  :src="currentRecipe.image.url"
                  elevation="1"
                  class="rounded thumbnail-static"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row class="mt-0">
            <v-col cols="6" class="d-flex justify-start pt-0">
              <v-btn
                  v-if="currentRecipe.isLiked"
                  :loading="likeBtnLoading"
                  @click="likeRecepie(currentRecipe.resourceid, false)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >fas fa-heart mr-1</v-icon
              >
                Polubione
              </v-btn>
              <v-btn
                  v-else
                  :loading="likeBtnLoading"
                  @click="likeRecepie(currentRecipe.resourceid)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >far fa-heart mr-1</v-icon
              >
                Polub
              </v-btn>
            </v-col>
            <v-col
                cols="6"
                class="d-flex justify-end mt-0 pt-0"
                v-if="userHasActivePlan"
            >
              <v-btn
                  v-if="
                  !diaryDay ||
                  diaryDay.meals.find(
                    (e) => e.id === currentRecipe.resourceid
                  ) === undefined
                "
                  :loading="diaryLoading"
                  @click="pushDiaryRecipe()"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >far fa-calendar-plus mr-1</v-icon
              >
                Wybieram
              </v-btn>
              <v-btn
                  v-else-if="diaryDay"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :style="'background-color: rgb(255, 50, 198);'"
                  class="
                  font-weight-bold
                  text-white
                  btn-primary
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
              ><v-icon
                  :elevation="'1'"
                  :style="'color: white; font-size: 21px !important;'"
              >fas fa-utensils mr-1</v-icon
              >
                Wybrane
              </v-btn>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row class="mt-0" v-if="currentRecipe.description">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                    dense
                    class="mt-2"
                    :style="'background-color: #EFDFFA;'"
                >
                  <span :style="'color: black'">
                    {{ currentRecipe.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-divider
              class="mt-2 mb-2 ml-2 mr-2"
              v-if="currentRecipe.description"
          ></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                  class="w-100 macro-chip"
                  :style="'color: white; background-color: #4B4B4B;'"
              >
                {{ `kcal: ${currentRecipe.kcal}` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                  class="w-100 macro-chip"
                  :style="'color: white; background-color: #B361FF;'"
              >
                {{ `Białko: ${currentRecipe.macro.protein}g` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                  class="w-100 macro-chip"
                  :style="'color: white; background-color: #FF8334;'"
              >
                {{ `Tłuszcze: ${currentRecipe.macro.fat}g` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                  class="w-100 macro-chip"
                  :style="'color: white; font-size: 11px; background-color: #FF32C6;'"
              >
                {{ `Węglowodany: ${currentRecipe.macro.carbs}g` }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="d-flex justify-center mt-0">
            <v-col>
              <template>
                <v-simple-table :style="'background-color: #EFDFFA;'">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center">
                        <span :style="'opacity: 0 !important;'">#</span>
                      </th>
                      <th
                          class="text-center font-size-hero-medium text-black"
                      >
                        Kroki przygotowania
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(prep, index) in currentRecipe.preparation"
                        :key="index"
                    >
                      <td class="text-left pt-2 pb-2 text-black">
                        {{ `${index + 1}:` }}
                      </td>
                      <td class="text-left pt-2 pb-2 text-black">
                        {{ ` ${prep}` }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="d-flex justify-center mt-0">
            <v-col cols="12" sm="12" md="6" lg="6">
              <template>
                <v-simple-table :style="'background-color: #EFDFFA;'" dense>
                  <template v-slot:default>
                    <thead>
                    <tr class="">
                      <th class="text-left">
                        <span :style="'opacity: 0 !important;'">#</span>
                      </th>
                      <th class="text-left font-size-hero-medium text-black">
                        Składniki ({{ currentRecipe.portions }} porcje)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(ing, index) in currentRecipe.ingredients"
                        :key="index"
                    >
                      <td class="text-left pt-2 pb-2 text-black">
                        {{ `${index + 1}:` }}
                      </td>
                      <td class="text-left pt-2 pb-2 text-black">
                        {{
                          ` ${ing.name} - ${ing.unit_value}${
                              contentConfig.recipe_units[ing.unit].label
                          } ${
                              ing.alt_unit != "none"
                                  ? "/ " + ing.alt_unit_value
                                  : ""
                          }${
                              ing.alt_unit != "none"
                                  ? contentConfig.recipe_alt_units[ing.alt_unit]
                                      .label
                                  : ""
                          }`
                        }}
                        <v-spacer></v-spacer>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <template>
                <v-simple-table
                    class=""
                    :style="'background-color: #EFDFFA;'"
                    dense
                >
                  <template v-slot:default>
                    <thead v-if="currentRecipe.spices.length > 0">
                    <tr>
                      <th class="text-left">
                        <span :style="'opacity: 0 !important;'">#</span>
                      </th>
                      <th class="text-left font-size-hero-medium text-black">
                        Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                      </th>
                    </tr>
                    </thead>
                    <thead v-else>
                    <tr>
                      <th
                          class="text-center font-size-hero-medium text-black"
                          colspan="2"
                      >
                        Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                      </th>
                    </tr>
                    </thead>
                    <tbody v-if="currentRecipe.spices.length > 0">
                    <tr
                        v-for="(ing, index) in currentRecipe.spices"
                        :key="index"
                    >
                      <td class="text-left pt-2 pb-2 text-black">
                        {{ `${index + 1}:` }}
                      </td>
                      <td class="text-left pt-2 pb-2 text-black">
                        {{ ` ${ing.name}` }}
                        <v-spacer></v-spacer>
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td class="text-center pt-2 pb-2" colspan="2">
                        {{ `Bez przypraw` }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>


    <v-container fluid >
      <v-row>
        <v-col cols="12">
          <h1>Ostatnio dodane treningi</h1>
          <h4 class="text-typo">{{ this.trainings.length }} wyniki</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            v-for="training in trainings.slice(page * 9, page * 9 + 9)"
            :key="training.resourceid"
        >
          <v-card
              class="transparent pa-1 thumbnail-container"
              @click="openTraining(training.resourceid)"
          >
            <v-img
                :src="training.thumbnail.url"
                elevation="1"
                class="rounded thumbnail-static"
            >
              <DifficultyStars
                  :color="'white'"
                  class="training-diff"
                  :rating="training.difficulty[0]"
              ></DifficultyStars>
              <template v-slot:placeholder>
                <v-sheet :color="`grey lighten-4`">
                  <v-skeleton-loader type="image"></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
            <div class="training-liked" v-if="training.isLiked">
              <v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 35px !important;'"
              >fas fa-heart</v-icon
              >
            </div>

            <h4 class="text-typo">
              {{ training.name }}
              <v-chip
                  class="ma-2"
                  x-small
                  color="green"
                  text-color="white"
                  v-if="!userHasActivePlan && training.is_free"
              >
                FREE
              </v-chip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                      v-bind="attrs"
                      v-on="on"
                      class="ma-2"
                      x-small
                      color="#e530ff"
                      text-color="white"
                      v-if="training.isRecommended"
                  >
                    Polecany
                  </v-chip>
                </template>
                <span
                >Odpowiada twoim celom oraz poziomowi
                            zaawansowania</span
                >
              </v-tooltip>
            </h4>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
            cols="12"
            class="d-inline-flex justify-center align-center"
        >
          <v-btn
              class="btn-primary button-primary-gradient"
              :disabled="page == 0"
              @click="prevPageTraining()"
          >
            <v-icon :style="'color: white;'" class="ma-2">
              fas fa-chevron-left
            </v-icon>
          </v-btn>
          <h4 class="text-typo mx-2">
            strona {{ page + 1 }}/{{ maxPage }}
          </h4>
          <v-btn
              class="btn-primary button-primary-gradient"
              :disabled="page >= maxPage - 1"
              @click="nextPageTraining()"
          >
            <v-icon :style="'color: white;'" class="ma-2">
              fas fa-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="trainingDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        width="800"
        :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="trainingDialog = false">
            <v-icon color="white">{{
                this.$vuetify.breakpoint.mobile
                    ? "fas fa-chevron-left"
                    : "fas fa-times"
              }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
          >{{ currentTraining.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row>
            <v-col cols="12">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                    v-if="currentTraining"
                    class="rounded"
                    :src="`https://player.vimeo.com/video/${currentTraining.vimeo_id}?h=401fac24f8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                    title=""
                ></iframe>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-start pt-0">
              <v-btn
                  v-if="currentTraining.isLiked"
                  :loading="likeBtnLoading"
                  @click="likeRecepie(currentTraining.resourceid, false)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >fas fa-heart mr-3</v-icon
              >
                Polubione
              </v-btn>
              <v-btn
                  v-else
                  :loading="likeBtnLoading"
                  @click="likeTraining(currentTraining.resourceid)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >far fa-heart mr-3</v-icon
              >
                Polub
              </v-btn>
            </v-col>

            <v-col class="d-flex justify-end align-center pt-0 mr-4">
              <DifficultyStars
                  v-if="currentTraining"
                  :tooltip="true"
                  :color="'#FF32C6'"
                  :rating="currentTraining.difficulty[0]"
              ></DifficultyStars>
            </v-col>
          </v-row>
          <v-row v-if="userHasActivePlan">
            <v-col class="d-flex justify-start mt-0 pt-0">
              <v-btn
                  v-if="
                  !diaryDay ||
                  diaryDay.trainings.find(
                    (e) => e.id === currentTraining.resourceid
                  ) === undefined
                "
                  :loading="diaryLoading"
                  @click="pushDiary()"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold transparent text-typo px-6 me-2 mb-1"
                  color="#5e72e4"
              ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
              >far fa-calendar-plus mr-3</v-icon
              >
                Wybieram
              </v-btn>
              <v-btn
                  v-else-if="diaryDay"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :style="'background-color: rgb(255, 50, 198);'"
                  class="
                  font-weight-bold
                  text-white
                  btn-primary
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
              ><v-icon
                  :elevation="'1'"
                  :style="'color: white; font-size: 21px !important;'"
              >fas fa-heartbeat mr-1</v-icon
              >
                Wykonany
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                    dense
                    class="mt-2"
                    :style="'background-color: #EFDFFA;'"
                >
                  <span :style="'color: black'">
                    {{ currentTraining.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                    dense
                    class="mt-2"
                    :style="'background-color: #EFDFFA;'"
                >
                  <h4 class="text-typo text-black">
                    <v-icon>fas fa-futbol mr-2</v-icon> Sprzęt
                  </h4>
                  <span :style="'color: black'">
                    <ul>
                      <li v-for="tool in currentTraining.tools" :key="tool">
                        {{
                          tools.find((tl) => {
                            return tl.value == tool;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                    dense
                    class="mt-2"
                    :style="'background-color: #EFDFFA;'"
                >
                  <h4 class="text-typo text-black">
                    <v-icon>fas fa-child mr-2</v-icon> Części ciała
                  </h4>
                  <span :style="'color: black'">
                    <ul>
                      <li v-for="bp in currentTraining.body_parts" :key="bp">
                        {{
                          body_parts.find((part) => {
                            return part.value == bp;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-overlay :opacity="1" :style="'z-index: 999999999;'" :value="pageLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { add } from 'date-fns'
import DifficultyStars from '@/components/util/DifficultyStars'

export default {
  name: "Recently Added",
  data: function () {
    return {
      searchLoading: false,
      pageLoading: true,
      likeBtnLoading: false,
      diaryLoading: false,
      tab: null,
      filterDialog: false,
      recipeDialog: false,
      currentRecipe: false,

      trainings: [],
      trainings_liked: [],
      all_trainings: [],
      trainingDialog: false,
      currentTraining: false,
      difficulty: [],
      body_parts: [],
      tools: [],

      searchValue: "",
      searchTimeout: false,

      free: [],
      categories: [],
      tags: [],
      recipes: [],
      recipes_liked: [],
      all_recipes: [],
      loading: true,
      selected: {
        free: [],
        categories: [],
        tags: [],
      },
      pageRecipe:0,
      maxPageRecipe:0,
      page:0,
      maxPage:0,
    };
  },
  components: {
    DifficultyStars,
  },
  mounted() {
    this.loadFilters();
    this.loadFiltersTrainings();
    this.loadRecipes();
    this.loadLikedRevipes();
    this.loadTrainings();
    this.loadLikedTrainings();
  },
  watch: {
    selected: {
      handler: function () {
        this.loadFilters();
        this.loadRecipes();
      },
      deep: true,
    },
    recipeDialog: {
      handler: function () {
        if (!this.recipeDialog) {
          this.currentRecipe = false;
        }
      },
    },
  },
  methods: {
    pushDiaryRecipe() {
      this.diaryLoading = true;

      this.$axios
          .post("/api/diary/addmeal", {
            resourceid: this.currentRecipe.resourceid,
            day:this.$getDate()
          })
          .then(async (response) => {
            if (response.data.success) {
              await this.$store.dispatch("fetchUser");
              this.$toast.open({
                message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
              this.diaryLoading = false;
            } else {
              this.diaryLoading = false;
              this.$toast.open({
                message: `<span class="font-weight-bold">Już dodane do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
            }
          })
          .catch(function (error) {
            this.diaryLoading = false;

            console.log(error);
          });
    },
    pushDiary() {
      this.diaryLoading = true;
      this.$axios
          .post("/api/diary/addtraining", {
            resourceid: this.currentTraining.resourceid,
            day:this.$getDate()
          })
          .then(async (response) => {
            this.diaryLoading = false;
            if (response.data.success) {
              await this.$store.dispatch("fetchUser");
              this.$toast.open({
                message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
            } else {
              this.$toast.open({
                message: `<span class="font-weight-bold">Już dodane do dzienniczka!</span>`,
                type: "success",
                duration: 3000,
                position: "bottom",
              });
            }
          })
          .catch(function (error) {
            this.diaryLoading = false;

            console.log(error);
          });
    },
    goToTab() {
      console.log(this.tab);
    },
    openRecipe(id) {
      let chosenRecipe = this.all_recipes.find((recipe) => {
        return recipe.resourceid === id;
      });

      if (
          (chosenRecipe.is_free || this.userHasActivePlan) &&
          !this.$store.getters.contentLockedDialog
      ) {
        this.currentRecipe = chosenRecipe;
        this.recipeDialog = true;
      } else if (!this.recipeDialog) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },
    closeRecipe() {
      this.currentRecipe = false;
      this.recipeDialog = false;
    },
    searchBounce() {
      try {
        clearTimeout(this.searchTimeout);
      } catch (error) {
        true;
      }
      this.searchTimeout = setTimeout(() => {
        this.loadRecipes(true);
      }, 700);
    },
    resetFilters: function () {
      this.selected = {
        free: [],
        categories: [],
        tags: [],
      };
      this.searchValue = "";
    },
    loadFilters: function () {
      this.$axios
          .post("/api/recipes/filters", {
            params: this.$_.omit(this.selected, "types"),
          })
          .then((response) => {
            this.free = response.data.free;
            this.categories = response.data.categories;
            this.tags = response.data.tags;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loadFiltersTrainings: function () {
      this.$axios
          .post("/api/trainings/filters", {
            params: this.$_.omit(this.selected, "types"),
          })
          .then((response) => {
            this.free = response.data.free;
            this.recommended = response.data.recommended;
            this.categories = response.data.categories;
            this.types = response.data.types;
            this.lengths = response.data.lengths;
            this.difficulty = response.data.difficulty;
            this.body_parts = response.data.body_parts;
            this.tools = response.data.tools;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loadRecipes: function (search = false) {
      if (search) {
        this.searchLoading = true;
      }
      this.$axios
          .post("/api/recipes/filtered", {
            params: { ...this.selected,
              newerThen: add(new Date, {weeks:-8})
            },
          })
          .then((response) => {
            this.recipes = response.data.filtered;
            this.loadPagesRecipe(response.data.filtered.length);
            this.all_recipes = response.data.all;
            this.pageLoading = false;
            this.searchLoading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loadLikedRevipes: function () {
      this.$axios
          .post("/api/recipes/liked", {
            params: this.selected,
          })
          .then((response) => {
            this.recipes_liked = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },

    likeRecepie: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
          .post(`/api/recipes/${like ? "like" : "unlike"}`, {
            resourceid: resourceid,
          })
          .then((response) => {
            if (response.data.success) {
              this.$set(this.currentRecipe, "isLiked", like);
              this.likeBtnLoading = false;
              this.loadRecipes();
              this.loadLikedRevipes();
            }
          })
          .catch(function (error) {
            this.likeBtnLoading = false;
            console.log(error);
          });
    },
    loadPagesRecipe : function(count){
      this.pageRecipe = 0;
      this.maxPageRecipe = Math.ceil(count/9);
    },
    prevPageRecipe: function(){
      if (this.pageRecipe>0)
      {
        this.pageRecipe-=1;
      }
    },
    nextPageRecipe: function(){
      if (this.pageRecipe<(this.maxPageRecipe-1))
      {
        this.pageRecipe+=1;
      }
    },

    loadTrainings: function () {
      this.$axios
          .post("/api/trainings/filtered", {
            params: {
              ...this.selected,
              newerThen: add(new Date, { weeks: -10 })
            }
          })
          .then((response) => {
            this.trainings = response.data.filtered;
            this.loadPages(response.data.filtered.length);
            this.all_trainings = response.data.all;
            this.pageLoading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loadLikedTrainings: function () {
      this.$axios
          .post("/api/trainings/liked", {
            params: this.selected,
          })
          .then((response) => {
            this.trainings_liked = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    openTraining(id) {
      let chosenTraining = this.all_trainings.find((training) => {
        return training.resourceid === id;
      });

      if (
          (chosenTraining.is_free || this.userHasActivePlan) &&
          !this.$store.getters.contentLockedDialog
      ) {
        this.currentTraining = chosenTraining;
        this.trainingDialog = true;
      } else if (!this.trainingDialog) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },

    likeTraining: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
          .post(`/api/trainings/${like ? "like" : "unlike"}`, {
            resourceid: resourceid,
          })
          .then((response) => {
            if (response.data.success) {
              this.$set(this.currentTraining, "isLiked", like);
              this.likeBtnLoading = false;
              this.loadTrainings();
              this.loadLikedTrainings();
            }
          })
          .catch(function (error) {
            this.likeBtnLoading = false;
            console.log(error);
          });
    },
    loadPages: function (count) {
      this.page = 0;
      this.maxPage = Math.ceil(count / 9);
    },
    prevPageTraining: function () {
      if (this.page > 0) {
        this.page -= 1;
      }
    },
    nextPageTraining: function () {
      if (this.page < this.maxPage - 1) {
        this.page += 1;
      }
    },
  },
  computed: {
    filtersEmpty() {
      return (
          this.selected.categories.length == 0 &&
          this.selected.tags.length == 0 &&
          this.selected.free.length == 0 &&
          (this.$vuetify.breakpoint.mdAndUp || this.searchValue == "")
      );
    },
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
    contentConfig() {
      return this.$store.state.contentConfig;
    },
    diaryDay() {
      return this.$store.state.user.diaryDay;
    },
  },
};
</script>
<style>
.free-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: green !important;
}

.recommended-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: rgb(229, 48, 255) !important;
}

.recipe-liked {
  position: absolute !important;
  top: 4px;
  right: 8px;
}

.recipe-diff {
  position: absolute !important;
  top: 4px;
  left: 8px;
}

/* .thumbnail-container .thumbnail-static {
  display: block;
}

.thumbnail-container .thumbnail-gif {
  display: none;
}

.thumbnail-container:hover .thumbnail-static {
  display: none;
}

.thumbnail-container:hover .thumbnail-gif {
  display: block;
} */

.recipe-search-input .v-input__prepend-outer {
  margin: 0px !important;
  padding: 10px !important;
  height: 40px !important;
}

.macro-chip {
  display: flex;
  justify-content: center;
}
.free-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: green !important;
}

.recommended-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: rgb(229, 48, 255) !important;
}

.training-liked {
  position: absolute !important;
  top: 4px;
  right: 8px;
}

.training-diff {
  position: absolute !important;
  top: 4px;
  left: 8px;
}

</style>
